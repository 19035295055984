import React, { useState } from "react";
import Slider from "react-slick";
import "./HeroSection.css";
import EnquiryForm from "../Pages/Enquiry";
import slider1 from "../../assets/slider1.jpg";
import slider2 from "../../assets/slider2.jpg";
import slider3 from "../../assets/slider3.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroSection = () => {
  const slides = [
    {
      image: slider1,
      title: "Software Development & Consulting",
      subtitle: "Transforming Businesses Through Innovative Software Solutions & Technologies",
      description: "We design, develop, and deliver intelligent custom software solutions and digital products for SMEs, enterprises, and startups, harnessing cutting-edge technologies, such as AI, Big Data, and Machine Learning.",
    },
    {
      image: slider2,
      title: "Empowering Your Business Growth",
      subtitle: "Experience Tailored Software Solutions",
      description: "Our solutions are customized to meet the unique needs of your business, ensuring sustainable growth and a competitive edge in the market.",
    },
    {
      image: slider3,
      title: "Your Trusted Technology Partner",
      subtitle: "Building Future-Ready Solutions",
      description: "Partner with us for reliable and scalable solutions that drive success and foster innovation in a rapidly evolving digital landscape.",
    },
  ];

  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleGetStartedClick = () => {
    setIsFormVisible(true);
  };

  const closeForm = () => {
    setIsFormVisible(false);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
  };

  return (
    <div className="heroSection">
      <Slider {...sliderSettings} className="heroSlider">
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            <img src={slide.image} alt={`Slide ${index + 1}`} className="heroImage" />
            <div className="textContainer">
              <h3 className="slideTitle">{slide.title}</h3>
              <h1 className="slideSubtitle">{slide.subtitle}</h1>
              <p className="slideDescription">{slide.description}</p>
              <button className="hero-button" onClick={handleGetStartedClick}>
                Explore Now
              </button>
            </div>
          </div>
        ))}
      </Slider>
      {isFormVisible && (
        <EnquiryForm service="Real Estate Services" closeForm={closeForm} />
      )}
    </div>
  );
};

export default HeroSection;
