import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import HeroSection from "../HeroSection/HeroSection";
import Property1 from "../../assets/Gallery/header2.png"; // Example image paths, replace with actual images
import Property2 from "../../assets/Gallery/header2.png";
import Property3 from "../../assets/Gallery/header2.png";
import Blogs1 from "../../assets/Blogs/blogs1.jpg";
import Testimonials from "../Testimonial/Testimonials";
import Modal from "./Modal";
import EnquiryForm from "./Enquiry";
import "./Home.css";

// Featured Properties Section
const FeaturedProperties = () => {
  const properties = [
    { title: "1 & 2BHK Flats In Deep Vihar", image: Property1 },
    { title: "Plots in Sector 24", image: Property2 },
    { title: "4BHK Flat In Rohini", image: Property3 },
  ];

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const handleEnquiryClick = (property) => {
    setSelectedProperty(property); // Set the selected property
    setIsFormVisible(true); // Show the enquiry form
  };

  const closeForm = () => {
    setIsFormVisible(false); // Hide the form when it's closed
  };

  return (
    <section className="featured-properties">
      <div className="container">
        <h2>Featured Property</h2>
        <div className="properties-container">
          {properties.map((property, index) => (
            <div key={index} className="property-card">
              <div className="property-image">
                <img src={property.image} alt={property.title} />
              </div>
              <div className="property-content">
                <h3>{property.title}</h3>
                <button
                  className="enquiry-button"
                  onClick={() => handleEnquiryClick(property)}
                >
                  Enquiry Now
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isFormVisible && (
        <EnquiryForm
          service={`Enquiry for ${selectedProperty?.title}`}
          closeForm={closeForm}
        />
      )}
    </section>
  );
};

// About Us Section
const AboutUs = () => {
  return (
    <section className="about-us">
      <div className="container">
        <h2>About Us</h2>
        <div className="about-content">
          <div className="about-text space-y-4">
            <p>
              We are a team of dedicated professionals committed to providing
              exceptional real estate and construction services. Our expertise
              spans across property sales, rentals, and custom building
              solutions, ensuring comprehensive support for all your real estate
              needs.
            </p>
            <p>
              At <strong>The Properties and Builders</strong>, we take pride in
              our attention to detail and commitment to excellence. Whether
              you're searching for your dream home, a lucrative investment, or
              tailored construction services, our experienced team ensures every
              project is managed with the utmost professionalism.
            </p>
            <p>
              Customer satisfaction is at the heart of what we do. With a focus
              on delivering transparency and personalized service, we
              continually innovate to meet and exceed your expectations. From
              seamless property transactions to customized building solutions,
              we strive to create a stress-free and rewarding experience for our
              clients.
            </p>
            <p>
              Beyond real estate and construction, we aim to build lasting
              relationships with our clients, fostering trust and reliability.
              Thank you for choosing
              <strong>The Properties and Builders</strong> — your trusted
              partner in turning your property dreams into reality.
            </p>
          </div>
          <div className="about-video">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/TNX4wDsZN-M?autoplay=1&loop=1&playlist=TNX4wDsZN-M"
              title="About Us Video"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

// Call to Action (CTA) Section
const CallToAction = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate("/contact"); // Change '/contact' to the actual path of your contact page
  };

  return (
    <section className="call-to-action">
      <div className="container">
        <h2>Ready to start your project?</h2>
        <button onClick={handleContactClick}>Contact Us</button>
      </div>
    </section>
  );
};

// Blog Preview Section
const BlogPreview = () => {
  const blogPosts = [
    {
      title: "Navigating the Property Market in 2024",
      content:
        "Explore the key trends and forecasts for the property market in 2024. From market analysis to investment opportunities, learn how to make informed decisions in the evolving landscape of property sales and purchases.",
      image: Blogs1, // Ensure images are relevant to the content
    },
    {
      title: "Expert Tips for First-Time Home Buyers",
      content:
        "Buying your first home can be daunting. Get expert advice tailored to help first-time buyers navigate through the complexities of purchasing a home, ensuring a smooth and successful transaction.",
      image: Blogs1,
    },
    {
      title: "Maximizing ROI in Real Estate",
      content:
        "Discover strategies for maximizing your return on investment in real estate. This guide covers everything from property selection to market timing, designed for both seasoned investors and new entrants.",
      image: Blogs1,
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const openModal = (post) => {
    setSelectedPost(post);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <section className="blog-preview">
      <div className="container">
        <h2>Insights from Vaani Properties</h2>
        <div className="blog-container">
          {blogPosts.map((post, index) => (
            <div
              key={index}
              className="blog-post"
              onClick={() => openModal(post)}
            >
              <div className="image-card">
                <img src={post.image} alt={post.title} />
              </div>
              <div className="description-card">
                <h3>{post.title}</h3>
                <p>{post.snippet}</p> {/* Use snippet instead of content */}
              </div>
            </div>
          ))}
        </div>
      </div>
      {modalOpen && <Modal post={selectedPost} closeModal={closeModal} />}
    </section>
  );
};

const Home = () => {
  return (
    <div>
      <HeroSection />
      <FeaturedProperties />
      <AboutUs />
      <CallToAction />
      <BlogPreview />
      <Testimonials />
    </div>
  );
};

export default Home;
